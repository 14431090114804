<template>
  <div class="row">
    <b-button :disabled="button.disabled" :tag="button.url ? 'router-link' : 'button'" :to="'/redirect/?to=' + button.url" :type="{'is-primary': button.style === 1, 'is-info': button.style === 2, 'is-success': button.style === 3, 'is-danger': button.style === 4, 'is-dark': button.style === 5}" v-for="(button, index) in components.filter(g => g.type == 2)" :key="index">
      {{ button.label}}
    </b-button>
  </div>
</template>

<script>
  export default {
    name: 'DiscordComponentRow',
    props: {
      components: Array
    },
    computed: {
    }
  }
</script>

<style>
  .row:not(:last-child) {
    padding-bottom: 8px;
  }
  .row .button:not(:last-child):not(.is-fullwidth) {
    margin-right: 0.5rem;
  }
  @media only screen and (max-width: 768px) {
    .row .button:not(:first-child) {
      margin-top: 0.5rem;
    }
  }
</style>
