<template>
  <div>
    <div v-if="!loaded">
    </div>
    <div v-else class="container Nunito" style="max-width: 100%">
      <section class="section headerSection">
        <div class="container has-text-centered">
          <div v-if="archive.guild.icon">
            <img class="icon-img has-shadow" style="height:128px; width: 128px;" v-if="!archive.guild.icon.includes('a_')" :class="{'static': archive.guild.icon.includes('a_')}" :src="'https://cdn.discordapp.com/icons/' + archive.guild.id + '/' + archive.guild.icon + '.' + ((archive.guild.icon || '').startsWith('a_') ? 'gif' : 'webp') + '?size=128'" :alt="archive.guild.name">
            <img class="icon-img has-shadow" style="height:128px; width: 128px;" v-else :src="'https://cdn.discordapp.com/icons/' + archive.guild.id + '/' + archive.guild.icon + '.gif?size=128'" :alt="archive.guild.name">
          </div>
          <h1 class="title noselect">{{ $store.getters.admin.streamerMode ? '????' : archive.guild.name }}'s Archive</h1>
          <h1 style="margin-bottom:5px" class="subtitle is-6 has-text-white noselect">Created in #{{archive.channel.name}} by {{archive.createdBy.username}}#{{archive.createdBy.discriminator}} at {{createDate}}</h1>
        </div>
      </section>
      <section class="section" id="secStack" style="padding: 1.5rem 10% 3rem" v-if="archive.channel.nsfw === true && nsfwBypassed === false">
        <div class="container has-text-centered">
          <h1 class="title noselect" style="color: red;">Warning! #{{archive.channel.name}} is an marked NSFW Channel!</h1>
          <h1 style="margin-bottom:10px" class="subtitle is-6 has-text-white noselect">18+ Users only. Press Bypass below to see the messages!<br>By default you are required to login and have Manage Messages perm.</h1>
          <b-button type="is-danger" @click="nsfwBypassed = !nsfwBypassed">Show Messages</b-button>
        </div>
      </section>
      <section class="section" id="secStack" style="padding: 1.5rem 10% 3rem" v-if="!(archive.channel.nsfw === true && nsfwBypassed === false)">
        <discord-messages :compact-mode="false">
          <template v-for="message in archive.content">
            <!-- :everyoneHereMention="message.content.raw ? (message.content.raw.match(/@(everyone|here)/g) ? true : false) : null" -->
            <DiscordMessageGrace :key="message.id" :bot="message.author.bot" :componentsArray="message.components" :roleColor="message.roleColor" :timestamp="new Date(message.created)" :edited="message.edited === 0 || !message.edited ? false : true" :avatar="(message.author.avatar ? message.author.avatarURL : message.author.defaultAvatarURL)" :author="message.author.username">
              <p v-if="(!message.content || !message.content || message.content.raw.length <= 0) && message.attachments.length <= 0 && message.embeds.length <= 0" style="color: red; font-weight: bold;">INTERACTION</p>
              <v-runtime-template v-else :template="`${editTextClean(message).toString()}`"></v-runtime-template>
              <!-- <p v-else >{{editTextClean(message).toString()}}</p> -->
              <DiscordEmbedGrace v-for="embed in message.embeds" :desc="parseMarkdown(embed.description, message)" :key="embed.type + Math.random()" slot="embeds" :timestamp="embed.timestamp ? new Date(embed.timestamp) : null" :footer-image="embed.footer ? (embed.footer.icon_url ? embed.footer.icon_url : null) : null" :color="embed.color ? '#' + embed.color.toString(16) : null" :title="embed.title" :thumbnail="embed.thumbnail ? (embed.thumbnail.proxyURL || embed.thumbnail.url) : null" :image="embed.image ? (embed.image.proxyURL || embed.image.url) : null">
                <embed-fields v-if="embed.fields.length > 0 " slot="fields">
                  <EmbedFieldGrace :desc="parseMarkdown(field.value, message)" :inline="field.inline" :title="field.name" v-for="field in embed.fields" :key="field.name">
                  </EmbedFieldGrace>
                </embed-fields>
                <span v-if="embed.footer" slot="footer">
                  {{embed.footer.text}}
                </span >
              </DiscordEmbedGrace>
              <GraceAttachmentBox v-for="attachment in message.attachments.filter(g => !g.width && !g.height)" :key="attachment.id" :id="attachment.id" :attachment="attachment.attachment" :name="attachment.name" :proxyURL="attachment.proxyURL" :size="attachment.size" :url="attachment.url" />
              <ArchiveImage v-for="attachment in message.attachments.filter(g => g.width && g.height)" :key="attachment.id" :name="attachment.name" :id="attachment.id" :src="attachment.url || attachment.proxyURL" :alt="attachment.name" />
            </DiscordMessageGrace>
          </template>
        </discord-messages>
      </section>
    </div>
  </div>
</template>

<script>
  import 'vue-select/dist/vue-select.css'
  import vSelect from 'vue-select'
  import { createPopper } from '@popperjs/core'
  import JsColor from '@/components/JsColor.vue'
  import Moment from 'moment'
  import DiscordEmbedGrace from '@/components/DiscordEmbedGrace'
  import EmbedFieldGrace from '@/components/EmbedFieldGrace'
  import GraceAttachmentBox from '@/components/GraceAttachmentBox'
  import DiscordMessageGrace from '@/components/DiscordMessageGrace'
  import VRuntimeTemplate from 'v-runtime-template'
  import ArchiveImage from '@/components/ArchiveImage'

  export default {
    name: 'guild-archive',
    components: {
      DiscordEmbedGrace,
      EmbedFieldGrace,
      GraceAttachmentBox,
      DiscordMessageGrace,
      ArchiveImage,
      VRuntimeTemplate
      // vSelect,
      // JsColor
    },
    data () {
      return {
        loaded: false,
        archive: null,
        user: null,
        nsfwBypassed: false,
        SelectPlacement: 'bottom',
        abbrNumber: n => {
          if (n < 1e3) return n
          if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
          if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
          if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
          if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'
        }
      }
    },
    watch: {
    },
    async created () {
      // this.$store.dispatch('getApi', { path: 'system/items', params: {} }).then(async (result) => {
      //   this.items = result.data
      // })
      this.getArchive()
    },
    methods: {
      editTextClean (str) {
        var str2 = str.content.raw.replaceAll('<', '&lt;').replaceAll('>', '&gt;') // eslint-disable-line
          .replaceAll(/@(everyone|here)/g, '<d-mention>$1</d-mention>')
          .replaceAll(/(\*{1,2})(.*?\S)(\*{1,2})/gim, '<b>$2</b>')
          .replaceAll(/(\*{1,1})(.*?\S)(\*{1,1})/gim, '<i>$2</i>')
          .replaceAll(/(_{1,1})(.*?\S)(_{1,1})/gim, '<i>$2</i>')
          .replaceAll(/`(.*)`/gim, '<code style="background-color: var(--main-color-bg-2); color: #fff;">$1</code>')
          .replaceAll(/``(.*)``/gim, '<code style="background-color: var(--main-color-bg-2); color: #fff;">$1</code>')
          .replaceAll(/```(.*)```/gim, '<code style="background-color: var(--main-color-bg-2); color: #fff;">$1</code>')
          .replaceAll(/&lt;@!?(\d{17,19})&gt;/g, (f, h) => {
            if (str.mentions.users.some(element => typeof element === 'string')) return `<d-mention type="user" color="${this.archive.guild.members.find(g => g.id === h) ? this.archive.guild.members.find(g => g.id === h).color : null}">${(this.archive.guild.members.find(g => g.id === h) ? this.archive.guild.members.find(g => g.id === h).username : h)}</d-mention>`
            else return `<d-mention type="user">${(str.mentions.users.find(g => g.id === h) ? str.mentions.users.find(g => g.id === h).username : h)}</d-mention>`
          })
          .replaceAll(/&lt;#(\d{17,19})&gt;/g, (f, h) => {
            if (str.mentions.users.some(element => typeof element === 'string')) return `<d-mention type="channel">${(this.archive.guild.channels.find(g => g.id === h) ? this.archive.guild.channels.find(g => g.id === h).name : h)}</d-mention>`
            else return `<d-mention type="channel">${(str.mentions.channels.find(g => g.id === h) ? str.mentions.channels.find(g => g.id === h).name : h)}</d-mention>`
          })
          .replaceAll(/&lt;@&(\d{17,19})&gt;/g, (f, h) => {
            if (str.mentions.users.some(element => typeof element === 'string')) return `<d-mention type="role" color="${(this.archive.guild.roles.find(g => g.id === h) ? (this.archive.guild.roles.find(g => g.id === h).color.includes('#') ? this.archive.guild.roles.find(g => g.id === h).color : '#' + this.archive.guild.roles.find(g => g.id === h).color.toString(16)) : null)}">${(this.archive.guild.roles.find(g => g.id === h) ? this.archive.guild.roles.find(g => g.id === h).name : h)}</d-mention>`
            else return `<d-mention type="role" color="${(str.mentions.roles.find(g => g.id === h) ? (str.mentions.roles.find(g => g.id === h).color.includes('#') ? str.mentions.roles.find(g => g.id === h).color : '#' + str.mentions.roles.find(g => g.id === h).color.toString(16)) : null)}">${(str.mentions.roles.find(g => g.id === h) ? str.mentions.roles.find(g => g.id === h).name : h)}</d-mention>`
          }) // eslint-disable-line no-useless-escape
          .replaceAll(/(^|[^\/])(www\.[\S]+(\b|$))/gim, '$1<router-link tag="a" :to="/redirect/?to=http://$2">$2</router-link>') // eslint-disable-line no-useless-escape
          .replaceAll(/(\b(https?):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig, '<router-link tag="a" :to="{path:\'/redirect/?to=$1\'}">$1</router-link>') // eslint-disable-line no-useless-escape
        return `<div>${str2}</div>`
      },
      parseMarkdown (message = '', str) {
        const htmlText = (message || '').replaceAll('<', '&lt;').replaceAll('>', '&gt;') // eslint-disable-line
          // .replaceAll(/^### (.*$)/gim, '<h3>$1</h3>')
          // .replaceAll(/^## (.*$)/gim, '<h2>$1</h2>')
          // .replaceAll(/^# (.*$)/gim, '<h1>$1</h1>')
          // .replaceAll(/^\> (.*$)/gim, '<blockquote>$1</blockquote>')
          .replaceAll(/(\*{1,2})(.*?\S)(\*{1,2})/gim, '<b>$2</b>')
          .replaceAll(/(\*{1,1})(.*?\S)(\*{1,1})/gim, '<i>$2</i>')
          .replaceAll(/(_{1,1})(.*?\S)(_{1,1})/gim, '<i>$2</i>')
          .replaceAll(/`(.*)`/gim, '<code style="background-color: var(--main-color-bg-2); color: #fff;">$1</code>')
          .replaceAll(/``(.*)``/gim, '<code style="background-color: var(--main-color-bg-2); color: #fff;">$1</code>')
          .replaceAll(/```(.*)```/gim, '<code style="background-color: var(--main-color-bg-2); color: #fff;">$1</code>')
          // .replaceAll(/!\[(.*?)\]\((.*?)\)/gim, "<img alt='$1' src='$2' />")
          .replaceAll(/\[(.*?)\]\((.*?)\)/gim, '<router-link tag="a" :to="{path:\'/redirect/?to=$2\'}">$1</router-link>')
          .replaceAll(/\n$/gim, '<br />')
          .replaceAll(/&lt;@!?(\d{17,19})&gt;/g, (f, h) => {
            if (str.mentions.users.some(element => typeof element === 'string')) return `<d-mention type="user" color="${this.archive.guild.members.find(g => g.id === h) ? this.archive.guild.members.find(g => g.id === h).color : null}">${(this.archive.guild.members.find(g => g.id === h) ? this.archive.guild.members.find(g => g.id === h).username : h)}</d-mention>`
            else return `<d-mention type="user">${(str.mentions.users.find(g => g.id === h) ? str.mentions.users.find(g => g.id === h).username : h)}</d-mention>`
          })
          .replaceAll(/&lt;#(\d{17,19})&gt;/g, (f, h) => {
            if (str.mentions.users.some(element => typeof element === 'string')) return `<d-mention type="channel">${(this.archive.guild.channels.find(g => g.id === h) ? this.archive.guild.channels.find(g => g.id === h).name : h)}</d-mention>`
            else return `<d-mention type="channel">${(str.mentions.channels.find(g => g.id === h) ? str.mentions.channels.find(g => g.id === h).name : h)}</d-mention>`
          })
          .replaceAll(/&lt;@&(\d{17,19})&gt;/g, (f, h) => {
            if (str.mentions.users.some(element => typeof element === 'string')) return `<d-mention type="role" color="${(this.archive.guild.roles.find(g => g.id === h) ? (this.archive.guild.roles.find(g => g.id === h).color.includes('#') ? this.archive.guild.roles.find(g => g.id === h).color : '#' + this.archive.guild.roles.find(g => g.id === h).color.toString(16)) : null)}">${(this.archive.guild.roles.find(g => g.id === h) ? this.archive.guild.roles.find(g => g.id === h).name : h)}</d-mention>`
            else return `<d-mention type="role" color="${(str.mentions.roles.find(g => g.id === h) ? (str.mentions.roles.find(g => g.id === h).color.includes('#') ? str.mentions.roles.find(g => g.id === h).color : '#' + str.mentions.roles.find(g => g.id === h).color.toString(16)) : null)}">${(str.mentions.roles.find(g => g.id === h) ? str.mentions.roles.find(g => g.id === h).name : h)}</d-mention>`
          }) // eslint-disable-line no-useless-escape

        return htmlText.trim()
      },
      vselectfixer (dropdownList, component, { width }) {
        dropdownList.style.width = width
        const popper = createPopper(component.$refs.toggle, dropdownList, {
          placement: this.SelectPlacement,
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -1]
              }
            },
            {
              name: 'toggleClass',
              enabled: true,
              phase: 'write',
              fn ({ state }) {
                component.$el.classList.toggle('drop-up', state.SelectPlacement === 'top')
              }
            }
          ]
        })
        return () => popper.destroy()
      },
      async getArchive () {
        if (!this.$store.getters.user) await this.$store.dispatch('sleep', 1000)
        this.$store.dispatch('getApi', { path: `plugin/archive/${this.$route.params.id}`, params: {} }).then(async (result) => {
          this.archive = result.data.results
          this.user = result.data.user
          this.loaded = true
        }).catch((error) => {
          console.log(error, 'error')
          if (Number(error.response.status) === 401) return this.$router.push(`/login?redirect=/archive/${this.$route.params.id}`)
          if (Number(error.response.status) === 403) return this.$router.push('/?reason=User does not have correct perms to view')
          if (Number(error.response.status) === 404) return this.$router.push('/404?reason=Archive not found')
          if (Number(error.response.status) === 500) return this.$router.push('/500?reason=' + error)
          return this.$router.push('/500?reason=unknown')
        })
      }
    },
    mounted () {
    },
    computed: {
      createDate () {
        const date = new Moment(Number(this.archive.timestamp))
        return date.format('dddd, MMMM Do YYYY, h:mm:ss a')
      }
    }
  }
</script>

<style>
</style>

<style scoped>

  .discord-messages {
    border: none !important;
    background-color: transparent !important;
  }

  .discord-message:hover {
    background-color: transparent !important;
  }

  .has-shadow, #pfp-skel, #leaderTextSkel, #userLeader-pfp-skel {
    box-shadow: 0 0.1rem 1.5rem rgba(0,0,0,.3) !important;
  }

  .has-shadow-1, #pfp-skel1 {
    box-shadow: 0 0.8rem 1.5rem rgba(0,0,0,.3) !important;
  }

  .icon-img, #pfp-skel, #pfp-skel1 {
    /* float: left; */
    border-radius: 100px;
    border-color: var(--main-color-bg-3);
    border-width: 4px;
    border-radius: 100px;
    border-style: solid;
    background-color: var(--main-color-bg) !important;
    height:128px;
    width:128px;
  }
  .headerSection {
    width: 100%;
    height: 260px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 30px;
    position: relative;
  }
</style>

<style>

</style>
