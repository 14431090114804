<template>
  <div class="archive-attachment-box">
    <a v-if="!error" :href="url || proxyURL" class="archive-attachment-name">{{ name }}</a>
    <p v-if="!error" class="archive-attachment-size">{{ sizeCorrect }}</p>
    <a v-if="error" class="archive-attachment-name" style="color: red !important;">{{ name }}</a>
  </div>
</template>

<script>
  export default {
    name: 'GraceAttachmentBox',
    props: {
      attachment: String,
      id: String,
      name: String,
      proxyURL: String,
      size: {
        type: Number,
        default: 0
      },
      url: String,
      error: Boolean
    },
    computed: {
      sizeCorrect () {
        const decimals = 2
        const bytes = this.size
        if (bytes === 0) return '0 Bytes'
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
      }
    }
  }
</script>

<style>
  .archive-attachment-box {
    max-width: 400px;
    padding: 10px;
    margin-bottom: 16px;
    background-color: rgb(47, 49, 54);
    border: 1px solid rgb(41, 43, 47);
    border-radius: 5px;
    color: rgb(185, 187, 190);
  }
  .archive-attachment-name {
    color: rgb(0, 176, 244);
    text-decoration: none;
    font-size: 16px;
  }
  .archive-attachment-size {
    margin: 0;
    font-size: 12px;
    color: #72767d;
    font-weight: 300;
  }
</style>
