<template>
  <div :class="{ 'discord-inline-field': inline }" class="discord-embed-field">
    <v-runtime-template v-if="descComp" :template="descComp"></v-runtime-template>
    <v-runtime-template v-if="desc" :template="`<p>${desc}</p>`"></v-runtime-template>
  </div>
</template>

<script>
  import VRuntimeTemplate from 'v-runtime-template'
  export default {
    name: 'EmbedFieldGrace',
    components: {
      VRuntimeTemplate
    },
    props: {
      title: {
        type: String,
        required: true
      },
      inline: Boolean,
      desc: String
    },
    computed: {
      descComp () {
        return `<div class="discord-field-title">${this.title}</div>`
      }
    }
  }
</script>

<style>
  .discord-embed .discord-embed-field {
    min-width: 100%;
    margin-top: 5px;
  }
  .discord-embed .discord-embed-field.discord-inline-field {
    flex-grow: 1;
    flex-basis: auto;
    min-width: 100px;
  }
  .discord-embed .discord-embed-field .discord-field-title {
    color: #fff;
    font-weight: 500;
    margin-bottom: 2px;
  }
  .discord-light-theme .discord-embed .discord-embed-field .discord-field-title {
    color: #747f8d;
  }
</style>
