import { render, staticRenderFns } from "./archive.vue?vue&type=template&id=5694663a&scoped=true&"
import script from "./archive.vue?vue&type=script&lang=js&"
export * from "./archive.vue?vue&type=script&lang=js&"
import style1 from "./archive.vue?vue&type=style&index=1&id=5694663a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5694663a",
  null
  
)

export default component.exports