
<template>
    <img v-if="status === 0" class="archive-attachment" :src="src" :alt="alt" @error="erroring()" />
    <video v-else-if="status === 1" class="archive-attachment" controls :src="src" :alt="alt" @error="erroring()" />
    <GraceAttachmentBox v-else :id="id" :name="name" :error="true" />
</template>

<script>
  import GraceAttachmentBox from './GraceAttachmentBox'
  export default {
    name: 'ArchiveImage',
    components: {
      GraceAttachmentBox
    },
    props: {
      src: {
        type: String,
        required: true
      },
      alt: String,
      id: String,
      name: String
    },
    data () {
      return {
        status: 0
      }
    },
    methods: {
      erroring () {
        this.status++
      }
    },
    computed: {
    }
  }
</script>

<style scoped>
  .archive-attachment {
    max-width: 400px;
    height: auto;
    border-radius: 4px;
  }
</style>
